exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-education-training-js": () => import("./../../../src/pages/education-training.js" /* webpackChunkName: "component---src-pages-education-training-js" */),
  "component---src-pages-giveaway-js": () => import("./../../../src/pages/giveaway.js" /* webpackChunkName: "component---src-pages-giveaway-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mission-statement-js": () => import("./../../../src/pages/mission-statement.js" /* webpackChunkName: "component---src-pages-mission-statement-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-thank-you-assessment-js": () => import("./../../../src/pages/thank-you-assessment.js" /* webpackChunkName: "component---src-pages-thank-you-assessment-js" */),
  "component---src-pages-thank-you-audit-js": () => import("./../../../src/pages/thank-you-audit.js" /* webpackChunkName: "component---src-pages-thank-you-audit-js" */),
  "component---src-pages-thank-you-contact-js": () => import("./../../../src/pages/thank-you-contact.js" /* webpackChunkName: "component---src-pages-thank-you-contact-js" */),
  "component---src-pages-thank-you-free-consultation-js": () => import("./../../../src/pages/thank-you-free-consultation.js" /* webpackChunkName: "component---src-pages-thank-you-free-consultation-js" */),
  "component---src-pages-zero-waste-audit-and-composition-study-js": () => import("./../../../src/pages/zero-waste-audit-and-composition-study.js" /* webpackChunkName: "component---src-pages-zero-waste-audit-and-composition-study-js" */),
  "component---src-pages-zero-waste-certification-js": () => import("./../../../src/pages/zero-waste-certification.js" /* webpackChunkName: "component---src-pages-zero-waste-certification-js" */),
  "component---src-pages-zero-waste-facility-assessment-js": () => import("./../../../src/pages/zero-waste-facility-assessment.js" /* webpackChunkName: "component---src-pages-zero-waste-facility-assessment-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

